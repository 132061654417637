import React, { useState } from "react";
import { useDimensions, useWindowSize } from "react-recipes";
import { Catalog } from "../components/Catalog";
import { Layout } from "../components/Layout";
import { useLocationContext } from "../lib/LocationProvider";
import { withPage } from "../lib/withPage";

const CatalogLayout = () => {
  const [isBottom, setIsBottom] = useState(false);
  const [wrapperRef, dimensions] = useDimensions();
  const { height } = useWindowSize();

  const hasScroll = dimensions && dimensions.height > height;

  return (
    <Layout
      ref={wrapperRef}
      onVisibilitySensorChange={isVisible => {
        setIsBottom(isVisible);
      }}
    >
      <Catalog
        isBottom={isBottom}
        hasScroll={hasScroll}
        filters={useLocationContext().query}
      />
    </Layout>
  );
};

export default withPage(CatalogLayout, {
  title: "Catalogue"
});
