import { useUserToken } from "@igloo-be-omnipartners/hooks";
import { couronnesToPoints } from "@royalcanin-fr-loyalty/common";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { FilterInput, Locale } from "../../../../../__generated__/globalTypes";
import { ProductFragment } from "./Fragments";
import {
  DynamicFilterProducts,
  DynamicFilterProductsVariables,
} from "./__generated__/DynamicFilterProducts";
import { Products, ProductsVariables } from "./__generated__/Products";

const ProductsQuery = gql`
  query Products(
    $token: String!
    $locale: Locale!
    $filter: FilterInput!
    $page: Float
    $limit: Float
  ) {
    products(
      token: $token
      locale: $locale
      filter: $filter
      page: $page
      limit: $limit
    ) {
      pageInfo {
        page
        count
        limit
        hasNextPage
      }
      result {
        ...ProductFragment
      }
    }
  }
  ${ProductFragment}
`;

export interface IUserProductFilterInput
  extends Omit<FilterInput, "order" | "direction"> {
  order?: string;
  couronnesRange?: [number, number];
}

const convertFilterInput = (filter: IUserProductFilterInput): FilterInput => {
  const { couronnesRange, order, filters, petfood, categories, search, newProduct, promo } = filter;
  return {
    order: order && order.split(" ")[0],
    direction: order && order.split(" ")[1],
    newProduct: typeof newProduct === "boolean" ? newProduct : undefined,
    promo,
    petfood,
    categories,
    filters,
    startPoint: couronnesRange && couronnesToPoints(couronnesRange[0]),
    endPoint: couronnesRange && couronnesToPoints(couronnesRange[1]),
    search,
  };
};

export const useProducts = (
  filter: IUserProductFilterInput,
  {
    page,
    limit,
  }: {
    page?: number;
    limit?: number;
  } = {},
) => {
  const token = useUserToken();
  const res = useQuery<Products, ProductsVariables>(ProductsQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale.fr,
      filter: convertFilterInput(filter),
      page: page || 1,
      limit: limit || 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageInfo = (res.data &&
    res.data.products &&
    res.data.products.pageInfo) || {
    page: 1,
    hasNextPage: false,
  };

  return {
    ...res,
    data: (res.data && res.data.products && res.data.products.result) || [],
    pageInfo,
    onLoadMore: async () => {
      if (!pageInfo.hasNextPage || res.loading) {
        return Promise.resolve();
      }

      return res.fetchMore({
        variables: {
          page: pageInfo.page + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            products: {
              ...prev.products,
              result: [
                ...prev.products.result,
                ...fetchMoreResult.products.result,
              ],
              pageInfo: fetchMoreResult.products.pageInfo,
            },
          };
        },
      });
    },
  };
};

const DynamicFilterProductsQuery = gql`
  query DynamicFilterProducts($token: String!) {
    dynamicFilterProducts(token: $token) {
      isTherePromoProducts
      isThereNewProducts
    }
  }
`;

export const useDynamicFilterProducts = () => {
  const token = useUserToken();
  const res = useQuery<DynamicFilterProducts, DynamicFilterProductsVariables>(
    DynamicFilterProductsQuery,
    {
      skip: !token,
      variables: {
        token,
      },
    },
  );

  return {
    dynamicFilterProducts: res.data?.dynamicFilterProducts,
    ...res,
  };
};
