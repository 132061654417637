import {
  Button,
  Checkbox,
  CheckboxListString,
  colors,
  Dropdown,
  ffdinPro,
  fontFamily,
  fontSize,
  Form,
  FormAutoSave,
  Input,
  Link,
  mediaQuery,
  pointsToCouronnes,
  SliderRange,
  useInputProps,
} from "@royalcanin-fr-loyalty/ui-kit";
import { omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { useCategory } from "../lib/hooks/useCatalogCategory";
import { useCatalogFilter } from "../lib/hooks/useCatalogFilter";
import { useProductPointsRange } from "../lib/hooks/useProductPointsRange";
import { IUserProductFilterInput } from "../lib/hooks/useProducts";

export const orders = [
  {
    label: "Valeur croissante",
    value: "price asc",
  },
  {
    label: "Valeur décroissante",
    value: "price desc",
  },
  {
    label: "Nom A-Z",
    value: "label asc",
  },
];

const FiltersTitle = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("16px")}
  color: ${colors.darkDk};
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;

    ${mediaQuery("tablet")`
      margin-bottom: 15px;
    `}
  }
`;

const ResetLink = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const SliderContainer = styled.div`
  margin: 0 6px;
`;

const FormContainer = styled.form`
  margin-right: 30px;

  /* Sticky is only available if all the left menu filters is visible in the screen otherwise you have to scroll to reach the save button of the menu */
  @media screen and (min-height: 845px) {
    position: sticky;
    top: 60px;
  }
`;

export interface ICategoryFiltersProps {
  values?: IUserProductFilterInput;
  onSubmit: (data: IUserProductFilterInput) => void;
  dynamicFilters: {
    isTherePromoProducts?: boolean;
    isThereNewProducts?: boolean;
  };
  autoSave?: boolean;
}

export const useDefaultFilters = () => {
  const { data: pointsRange } = useProductPointsRange();
  return {
    order: orders[0].value,
    couronnesRange: [0, pointsRange.max] as [number, number],
  };
};

export const CatalogFilters = ({
  values,
  onSubmit,
  dynamicFilters: { isThereNewProducts, isTherePromoProducts },
  autoSave,
}: ICategoryFiltersProps) => {
  const { data: categories } = useCategory();
  const { data: filters } = useCatalogFilter();
  const { data: pointsRange } = useProductPointsRange();
  const initialValues = useDefaultFilters();

  return (
    <Form
      initialValues={values || initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { reset } }) => {
        return (
          <FormContainer onSubmit={handleSubmit}>
            {(autoSave || typeof autoSave === "undefined") && (
              <FormAutoSave onSubmit={handleSubmit} />
            )}
            <Item>
              <FiltersTitle>Chercher</FiltersTitle>
              <Input {...useInputProps({ name: "search" })} fullWidth />
            </Item>
            <Item>
              <FiltersTitle>Trier par</FiltersTitle>
              <Dropdown<string>
                items={orders}
                {...omit(useInputProps({ name: "order" }), ["helperText"])}
                fullWidth
              />
            </Item>
            <Item>
              <FiltersTitle>Filtres</FiltersTitle>
              <div
                style={{
                  ...(isTherePromoProducts
                    ? { marginBottom: 12 }
                    : { display: "none" }),
                }}
              >
                <Checkbox
                  {...useInputProps({
                    name: "promo",
                    config: {
                      type: "checkbox",
                    },
                  })}
                  id="promo"
                >
                  Promo
                </Checkbox>
              </div>
              <div
                style={{
                  ...(isThereNewProducts
                    ? { marginBottom: 12 }
                    : { display: "none" }),
                }}
              >
                <Checkbox
                  {...useInputProps({
                    name: "newProduct",
                    config: {
                      type: "checkbox",
                    },
                  })}
                  id="newProduct"
                >
                  Nouveautés
                </Checkbox>
              </div>
              <CheckboxListString
                items={filters.map(({ label, id }) => ({
                  label,
                  value: id,
                }))}
                {...useInputProps({ name: "filters" })}
              />
            </Item>
            <Item>
              <FiltersTitle>Catégories</FiltersTitle>
              <CheckboxListString
                items={categories.map(({ label, id }) => ({
                  label,
                  value: id,
                }))}
                {...useInputProps({ name: "categories" })}
              />
            </Item>
            <Item>
              <FiltersTitle>Couronnes</FiltersTitle>
              <SliderContainer>
                <SliderRange
                  {...useInputProps({ name: "couronnesRange" })}
                  range={{
                    min: 0,
                    max: pointsToCouronnes(pointsRange.max),
                  }}
                />
              </SliderContainer>
            </Item>
            <Item>
              <Button type="submit" fullwidth>
                Appliquer les filtres
              </Button>
              <ResetLink>
                <Link
                  onClick={() => {
                    reset(initialValues);
                    handleSubmit();
                  }}
                  dark
                >
                  Effacer
                </Link>
              </ResetLink>
            </Item>
          </FormContainer>
        );
      }}
    />
  );
};
