import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  CatalogFilter,
  CatalogFilterVariables,
} from "./__generated__/CatalogFilter";

const CatalogFilterQuery = gql`
  query CatalogFilter($token: String!, $locale: Locale!) {
    catalogFilters(token: $token, locale: $locale) {
      id
      label
    }
  }
`;

export const useCatalogFilter = () => {
  const token = useUserToken();
  const res = useQuery<CatalogFilter, CatalogFilterVariables>(
    CatalogFilterQuery,
    {
      skip: !token,
      variables: {
        token,
        locale: Locale.fr,
      },
    },
  );

  const filters = (res.data && res.data.catalogFilters) || [];

  return {
    ...res,
    data: filters,
  };
};
