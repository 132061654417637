import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  ProductPointsRange,
  ProductPointsRangeVariables,
} from "./__generated__/ProductPointsRange";

const ProductPointsRangeQuery = gql`
  query ProductPointsRange($token: String!) {
    productMaxPoints(token: $token)
    productMinPoints(token: $token)
  }
`;

export const useProductPointsRange = () => {
  const token = useUserToken();
  const res = useQuery<ProductPointsRange, ProductPointsRangeVariables>(
    ProductPointsRangeQuery,
    {
      skip: !token,
      variables: {
        token,
      },
    },
  );

  const productsRange = (res.data && {
    min: res.data.productMinPoints,
    max: res.data.productMaxPoints,
  }) || {
    min: 0,
    max: 0,
  };

  return {
    ...res,
    data: productsRange,
  };
};
