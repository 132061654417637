import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  CatalogCategory,
  CatalogCategoryVariables,
} from "./__generated__/CatalogCategory";

const CatalogCategoryQuery = gql`
  query CatalogCategory($token: String!, $locale: Locale!) {
    catalogCategories(token: $token, locale: $locale) {
      id
      label
    }
  }
`;

export const useCategory = () => {
  const token = useUserToken();
  const res = useQuery<CatalogCategory, CatalogCategoryVariables>(
    CatalogCategoryQuery,
    {
      skip: !token,
      variables: {
        token,
        locale: Locale.fr,
      },
    },
  );

  const categories = (res.data && res.data.catalogCategories) || [];

  return {
    ...res,
    data: categories,
  };
};
