import {
  CatalogFilterButton,
  Media,
  Swipper,
} from "@royalcanin-fr-loyalty/ui-kit";
import React from "react";
import { CatalogFilters, ICategoryFiltersProps } from "./CatalogFilters";

export const CatalogFiltersMobile = ({
  onSubmit,
  setCatalogFiltersMobileIsOpen,
  catalogFiltersMobileIsOpen,
  hasScroll,
  dynamicFilters,
}: {
  setCatalogFiltersMobileIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogFiltersMobileIsOpen: boolean;
  hasScroll: boolean;
} & ICategoryFiltersProps) => (
  <Media when={"<=768"}>
    <CatalogFilterButton
      hasScroll={hasScroll}
      onClick={() => setCatalogFiltersMobileIsOpen(true)}
    />
    <Swipper
      open={catalogFiltersMobileIsOpen}
      onClose={() => setCatalogFiltersMobileIsOpen(false)}
    >
      <CatalogFilters
        autoSave={false}
        dynamicFilters={dynamicFilters}
        onSubmit={onSubmit}
      />
    </Swipper>
  </Media>
);
